.btn {
  relative();
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font: 18px/24px $font-base;
  color: $white;
  border: 2px solid;
  padding: 13px 43px;
  border-radius: 100px;
  min-width: 280px;
  transform: translateZ(0);
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  @media $display-breakpoints.ms-and-down {
    font-size: vmin(18px);
    line-height: vmin(24px);
    padding: vmin(14px) vmin(43px);
    min-width: vmin(280px);
    white-space: nowrap;
  }

  &[disabled='disabled'] {
    pointer-events: none;
    opacity: .5;
  }

  {hover-focus-active} {
    text-decoration: none;
    outline: none;
  }

  &:hover {
    background-color: transparent;
  }

  &--icon {
    svg {
      size(20px);
      absolute(16px, 73px)
      @media $display-breakpoints.ms-and-down {
        size(vmin(20px));
        absolute(vmin(16px), vmin(73px))
      }
    }
  }
  &--secondary {
    border: 2px solid;
    padding: 12px 43px;
    background-color: transparent;
    &:hover {
      color: $white;
    }
  }
  &--plain {
    background: transparent;
    padding: 0;
    min-width: 0;
    border: 0;
    font: 400 18px/24px $font-base;
    border-radius: 0;
    box-shadow: none;
    color: $black;
    text-decoration: underline;
    @media $display-breakpoints.ms-and-down {
      font-size: vmin(15px);
      line-height: vmin(24px);
    }
    &:hover {
      background: transparent;
      text-decoration: none;
    }
  }
}

.btn-menu {
  size(vh(28px), vh(10px));
  cursor: pointer;

  @media $display-breakpoints.ms-and-down {
    size(vmin(28px), vmin(10px));
  }

  &:hover {
    .top,
    .bottom,
    .middle{
      //background: $blue;
    }
  }

  .top,
  .bottom,
  .middle{
    absolute(0px, auto, auto, 0px);
    size(100%, vh(2px));
    background: $black;
    transition: transform 0.3s, background-color .2s;
    @media $display-breakpoints.ms-and-down {
      height: vmin(2px);
    }
  }

  .top {
    top: 0;
  }
  .bottom {
    bottom: 0;
    top: auto;
  }
  .middle {
    top: 14px;
    width: 20px;
    @media $display-breakpoints.ms-and-down {
      top: vmin(14px);
      width: vmin(20px);
    }
  }
  &.opened {
    .top {
      top: vh(4px);
      transform: rotate(-45deg);
      @media $display-breakpoints.sm-and-down {
        background: $white;
      }
      @media $display-breakpoints.ms-and-down {
        top: vmin(4px);
        transform: rotate(-45deg);
      }
    }
    .middle {
      display: none;
    }
    .bottom {
      bottom: vh(3px);
      transform: rotate(45deg);
      @media $display-breakpoints.sm-and-down {
        background: $white;
      }
      @media $display-breakpoints.ms-and-down {
        bottom: vmin(3px);
        transform: rotate(45deg);
      }
    }
  }
}

.btn-volume {
  color: $white;

  &.off {
    color: $primary;
  }
}

.btn-share {
  size(2.5rem);
  padding: 12.5px;
  margin: -12.5px;
}

.btn-more {
  color: $primary;
  padding: 0;
  font: 600 10px/1 $font-base;
  text-transform: uppercase;
  flexy(center, flex-start);

  .text {
    margin: 0;
  }

  .icon {
    margin-left: 0.5em;
    transition: transform 0.3s;
    transform: rotate(0deg);
    transform-origin: center;

    .open & {
      transform: rotate(180deg);
    }
  }
  .hide {
    display: none;

    .open & {
      display: inline;
    }
  }

  .read {
    .open & {
      display: none;
    }
  }
}
