.qr-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  .custom-file-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.file-uploaded {
      label {
        opacity: 0;
      }
    }
  }
  label{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    background-color: $black;
    transition: opacity 0.3s ease;
    &::after {
      content: "Загрузить фото чека";
      margin-bottom: 16px;
      padding-top: 40px;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.2;
      background-image: url("../img/plus.svg");
      background-repeat: no-repeat;
      background-size: 27px auto;
      background-position: top center;
    }
    &:first-child {
      pointer-events: none;
      z-index: 2;
    }
    &.custom-file-container__custom-file {
      opacity: 0;
      cursor: pointer;
      z-index: 1;
    }
  }
  .custom-file-container__image-preview {
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.qr-block__label {
  margin: 0 0 6px;
  min-height: 19px;
  color: $black;
}
.qr-block__field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 230px;
  border: 1px solid red;
  overflow: hidden;
  @media $display-breakpoints.ms-and-down {
    height: vmin(210px);
  }

  img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.qr-block__upload {
  position: relative;
  width: 100%;
  height: 100%;
}
