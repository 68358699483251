@media $display-breakpoints.lg-and-up {
  .a {
    &-fade {
      opacity: 0;
      transition: opacity 0.3s;

      .animate > & {
        opacity: 1;
      }
    }

    &-fade-slide {
      opacity: 0;
      transform: translateY(2rem) translateZ(0);
      transition: transform 0.3s, opacity 0.3s;

      .animate > & {
        opacity: 1;
        transform: translateY(0) translateZ(0);
      }
    }

    &-ordered {
      for $i in 0...10 {
        &:nth-child({$i}) {
          transition-delay: 0.2s * $i;
        }
      }
    }

    &-delay {
      for $i in 0...5 {
        &-{$i} {
          transition-delay: 0.2s * $i;
        }
      }
    }
  }
}

@keyframes blue_a {
  0 {
    transform: translateY(0%);
    opacity: 1;
  }
  16.4% {
    transform: translateY(100%);
    opacity: 1;
  }
  16.5% {
    transform: translateY(-100%);
    opacity: 0;
  }
  16.6% {
    transform: translateY(-100%);
    opacity: 1;
  }
  33% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes yellow_a {
  0 {
    transform: translate(0%, 0%);
    opacity: 1;
  }
  66.6% {
    transform: translate(0%, 0%);
    opacity: 1;
  }
  83.2% {
    transform: translate(-100%, -50%);
    opacity: 1;
  }
  83.3% {
    transform: translate(100%, 50%);
    opacity: 0;
  }
  83.4% {
    transform: translate(100%, 50%);
    opacity: 1;
  }
  100% {
    transform: translate(0%, 0%);
    opacity: 1;
  }
}

@keyframes red_a {
  0 {
    transform: translate(0%, 0%);
    opacity: 1;
  }
  33.3% {
    transform: translate(0%, 0%);
    opacity: 1;
  }
  49.9% {
    transform: translate(100%, -50%);
    opacity: 1;
  }
  50% {
    transform: translate(-100%, 50%);
    opacity: 0;
  }
  50.1% {
    transform: translate(-100%, 50%);
    opacity: 1;
  }
  66.7% {
    transform: translate(0%, 0%);
    opacity: 1;
  }
  100% {
    transform: translate(0%, 0%);
    opacity: 1;
  }
}
