.main-menu {
  position: fixed;
  top: vh(50px);
  right: vh(99px);
  box-sizing: border-box;
  z-index: 100;
  transition: right 0.3s ease-in-out;
  overflow: hidden;
  @media $display-breakpoints.sm-and-down {
    fixed(0px);
    z-index: 9;
    right: -100%;
    background: rgba($black, .8);
    padding: 130px 20px 40px;
    size(100%);
  }
  &.opened {
    @media $display-breakpoints.sm-and-down {
      right: 0;
    }
  }
  &__link {
    transition: color 0.2s ease-in-out;
    margin-right: vh(48px);
    @media $display-breakpoints.sm-and-down {
      opacity: 0;
      margin-right: 0;
      color: $white;
      margin-bottom: 32px;
      text-align: center;
      display: block;
    }

    {hover-focus-active} {
      color: $blue;
    }
    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }
    &:hover {
      color: $blue;
      transition-delay: 0s !important;
    }
    &.active {
      color: $blue;
      pointer-events: none;
    }
    &.disabled {
      pointer-events: none;
    }
    .opened & {
      transition color 0.2s ease-in-out, opacity 0.3s ease-in-out 0s;
      @media $display-breakpoints.sm-and-down {
        opacity: 1;
      }
      for $i in 0...10 {
        &:nth-child({$i}) {
          transition-delay: 0.15s * $i;
        }
      }
    }
  }
}
