.tabs {
  margin-top: 43px;
  @media $display-breakpoints.ms-and-down {
    margin-top: vmin(38px);
  }
  &__panel {
    @media $display-breakpoints.sm-and-down {
      max-width: 100%;
      overflow: hidden;
    }
  }
  .tab__inner {
    & + .tab__inner {
      margin-top: 12px;
      @media $display-breakpoints.ms-and-down {
        margin-top: vmin(12px);
      }
    }
  }
  &__content {
    margin-top: 72px;
    padding-left: 420px;
    @media $display-breakpoints.md-and-down {
      padding-left: 360px;
    }
    @media $display-breakpoints.sm-and-down {
      margin-top: 15px;
      padding-left: 190px;
    }
    @media $display-breakpoints.ms-and-down {
      margin-top: vmin(8px);
      padding-left: 0;
    }
  }
  &__controls {
    flexy(space-between, center);
    counter-reset: step-list;
    @media $display-breakpoints.sm-and-down {
      justify-content flex-start;
      height: 120px;
    }
    @media $display-breakpoints.ms-and-down {
      height: vmin(120px);
    }
  }
  .tab {
    display none;
  }
  &__navs {
    absolute(0px, 0px);
    size(100%, 0px);
    display none;

    @media $display-breakpoints.sm-and-down {
      display block;
    }
  }
  &__prev,
  &__next {
    size(1em);
    font-size: 30px;
    line-height 1;
    color: $white;
    absolute(0px);
    transform: translateY(34px);
    cursor pointer;
    z-index: 2;
  }
  &__prev {
    left vmin(30px);
  }
  &__next {
    right vmin(30px);
  }
}

.tab-ctrl {
  flexy(center, center);
  flex: 1 1 auto;
  cursor: pointer;
  opacity: .5;
  padding-top: 60px;
  @media $display-breakpoints.sm-and-down {
    min-height: 76px;
    width: 100%;
    absolute(0px, 0px);
    transform: translateX(-100%);
    transition: transform 0.3s;
  }
  @media $display-breakpoints.ms-and-down {
    padding-top: vmin(52px);
  }
  &:first-child {
    &::before {
      content: '';
      background: url(../img/1.png) center no-repeat;
      background-size: contain;
    }
  }
  &::before {
    counter-increment: step-list;
    content: counter(step-list);
    absolute(0px, 50%);
    transform: translateX(-50%);
    size(57px);
    border-radius: 50%;
    background-color: $redMain;
    color: $black;
    text-align: center;
    font-size: 35px;
    line-height: 57px;
    @media $display-breakpoints.ms-and-down {
      font-size: vmin(35px);
      line-height: vmin(57px);
      size(vmin(57px), vmin(57px));
    }
  }
  &::after {
    content: '';
    size(82px, 2px);
    background-color: $redMain;
    absolute(28px, auto, auto, 0px);
    transform: translateX(50%);
    @media $display-breakpoints.sm-and-down {
      display: none;
    }
  }
  &:last-child {
    margin-right: 0;
    &::after {
      display: none;
    }
  }
  &__date {
    display: block;
    font: 400 18px/24px $font-base;
    color: $white;
    letter-spacing: normal;
    @media $display-breakpoints.ms-and-down {
      font-size: vmin(18px);
      line-height: vmin(24px);
    }
  }
  &__title {
    font: 500 28px/30px $font-base;
    color: $redMain;
    text-align: center;
    letter-spacing: 0.1em;
    -moz-user-select: none;
    -o-user-select:none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
     user-select: none;
    @media $display-breakpoints.sm-and-down {
      text-align: center;
    }
    @media $display-breakpoints.ms-and-down {
      max-width: vmin(220px);
      font-size: vmin(28px);
      line-height: vmin(40px);
    }
  }

  &.active {
    opacity: 1;
    @media $display-breakpoints.sm-and-down {
      transform: translateX(0);

      & + .tab-ctrl {
        transform: translateX(100%);
      }
    }
    &::after {
      opacity: .5;
    }
  }
}
