/* ==========================================================================
  BASIC - LAYOUT
   ========================================================================== */
html, body {
  background: $white;
  font: normal $font-size-base/1.5 $font-base;
  color: $black-l;
  overflow-x: hidden;

  .is-scrolling {
    pointer-events: none;
  }
}
html {
  &.popup-opened {
    overflow: hidden;
  }
}
body {
  min-width: initial;
  .popup-opened & {
    overflow: hidden;
  }
}
.loader {
  fixed(0px, 0px);
  size(100%);
  flexy(center);
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  z-index: -1;
  transition: opacity .2s ease-in-out;
  &.active {
    opacity: 1;
    z-index: 999;
  }
}
.l-container {
  relative();
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  min-width: $container-widths.min;
  max-width: $container-widths.max;
  padding: 0 40px;

  @media $display-breakpoints.sm-and-down {
    max-width: 748px;
    padding: 0 10px;
  }
  @media $display-breakpoints.ms-and-down {
    padding: 0 vmin(20px);
    max-width: vmin(360px);
  }
  &-fluid {
    padding: 0 1.5rem;
  }
}

.section {
  absolute(100%, 0px);
  size(100%, 100vh);
  pointer-events: none;
  z-index: 1;
  background: $white;
  transition: top .3s ease-in-out .3s;
  @media $display-breakpoints.m-and-down {
    position: relative;
    height: auto;
    min-height: 100vh;
    pointer-events: auto;
    border-bottom: 1px solid #D1D7D9;
  }
  @media $display-breakpoints.ms-and-down {
    max-width: vmin(375px, 375);
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (max-device-width: 767px) and (orientation: landscape) {
    min-height: vmin(530px);
    background-color: $white;
    overflow: hidden;
  }
  &::before {
    content: '';
    size(100%);
    absolute(0px, 0px);
    background: rgba($black, .4);
    pointer-events: none;
    transition: opacity .1s ease-in-out;
    z-index: 3;
    @media $display-breakpoints.m-and-down {
      display: none;
    }
  }
  &.active {
    pointer-events: auto;
    top: 0;
    z-index: 3;
    transition-delay: 0s;
    &::before {
      opacity: 0;
    }
  }
  &:last-child {
    border-bottom: none;
  }
}

.page-content {
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  transition: opacity .2s ease-in-out;
  @media $display-breakpoints.m-and-down {
    height: auto;
  }
  @media only screen and (max-device-width: 767px) and (orientation:landscape) {
    background-color: #ccc;
  }
  .page--popups & {
    padding: 80px 30px;
    min-height: calc(100vh - 80px);
  }
  .invisible & {
    opacity: 0;
  }
}

._mobile {
  display: none !important;
  @media $display-breakpoints.ms-and-down {
    display: block !important;
  }
}

._tablet {
  display: none !important;
  @media $display-breakpoints.m-and-down {
    display: block !important;
  }
}

._desktop {
  @media $display-breakpoints.m-and-down {
    display: none !important;
  }
}

.layout {
  width: 100vw;

  .page {
    flex-grow: 1;
    width: 100%;
  }
}

.l-spacer {
  flex-grow: 1
}

.l-float {
  &--left {
    float: left;

    ^[0]--outside& {
      margin-left: -52%;
    }
  }
  &--right {
    float: right;

    ^[0]--outside& {
      margin-right: -52%;
    }
  }
  &--outside {
    width: 75%;
  }
}

video {
  display: block;
  width: 100%;
  height: auto;
}

body > canvas, .wave-image {
  display: none;
}
