.page--home {

}
.logo {
	padding-left: vh(100px);
	padding-top: vh(56px);
	opacity: 0;
	transform: translateY(vmin(-20px));
	transition: all .3s ease-in-out .5s;
	@media $display-breakpoints.m-and-down {
		padding-left: 0;
		padding-top: vmin(56px);
		opacity: 1;
		transform: none;
	}
	@media $display-breakpoints.ms-and-down {
		padding-top: vmin(59px);
	}
	.active & {
		transform: translateY(vmin(0px));
		opacity: 1;
	}
	&__img {
		display: block;
		size(vh(224px), vh(88px));
		@media $display-breakpoints.m-and-down {
			margin: 0 auto;
		}
		@media $display-breakpoints.ms-and-down {
			size(vmin(109px), vmin(43px));
		}
	}
  &__subtitle {
    margin-top: vh(12px);
		@media $display-breakpoints.m-and-down {
			text-align: center;
			max-width: vmin(160px);
			margin-left: auto;
			margin-right: auto;
		}
		@media $display-breakpoints.ms-and-down {
			max-width: vmin(170px);
			margin-top: vmin(10px);
		}
  }
}
.scroll-down {
  absolute(auto, 50%, vh(133px), auto);
	opacity: 0;
  margin-left: vh(-8px);
	transition: all .3s ease-in-out 1s;
	@media $display-breakpoints.m-and-down {
		bottom: vmin(35px);
		opacity: 1;
	}
	@media $display-breakpoints.ms-and-down {
		bottom: vmin(74px);
		margin-left: vmin(-8px);
	}
	.active & {
		bottom: vh(113px);
		opacity: 1;
	}
  .icon {
    size(vh(16px), vh(110px));
		@media $display-breakpoints.m-and-down {
			size(vmin(8px), vmin(55px));
		}
		@media $display-breakpoints.ms-and-down {
			size(vmin(16px), vmin(50px));
		}
  }
}
.intro {
  &__title {
    text-align: center;
    margin-top: vh(41px);
		opacity: 0;
		transform: translateY(vmin(20px));
		transition: all .3s ease-in-out 1s;
		@media $display-breakpoints.m-and-down {
			opacity: 1;
			transform: none;
		}
		@media $display-breakpoints.ms-and-down {
			margin-top: vmin(9px);
		}
		.active & {
			transform: translateY(vmin(0px));
			opacity: 1;
		}
  }
  &-logo {
    flexy();
    width: vh(798px);
    margin: vh(100px) auto 0;
		@media $display-breakpoints.m-and-down {
			justify-content: center;
			width: auto;
		}
		@media $display-breakpoints.ms-and-down {
			margin-top: vmin(87px);
		}
    &__title {
      margin-top: vh(30px);
			opacity: 0;
			transform: translateX(vmin(20px));
			transition: all .3s ease-in-out 0.9s;
			@media $display-breakpoints.m-and-down {
				opacity: 1;
				transform: none;
			}
			.active & {
				transform: translateX(vmin(0px));
				opacity: 1;
			}
      &-icon {
        size(vh(514px), vh(138px));
				@media $display-breakpoints.m-and-down {
					size(vmin(128px), vmin(34px));
				}
				@media $display-breakpoints.ms-and-down {
					size(vmin(147px), vmin(40px));
				}
      }
    }
  }
  &-cube {
    size(vh(230px), vh(248px));
		margin-right: vh(53px);
		@media $display-breakpoints.m-and-down {
			size(vmin(76px), vmin(82px));
		}
		@media $display-breakpoints.ms-and-down {
			margin-right: vmin(15px);
			size(vmin(66px), vmin(71px));
		}
    &__blue, &__yellow, &__red {
      position: absolute;
			overflow: hidden;
    }
    &__blue {
      bottom: vh(7px);
      left: 0;
			opacity: 0;
			transform: translate(vmin(-10px), vmin(10px));
			transition: all .3s ease-in-out .6s;
			@media $display-breakpoints.ms-and-down {
				bottom: 0;
				left: vmin(1px);
			}
			.active & {
				transform: translate(vmin(0px), vmin(0px));
				opacity: 1;
			}
      svg {
        size(vh(107px), vh(173px));
		    animation: 2s ease-in-out 2s infinite blue_a;
				@media $display-breakpoints.m-and-down {
					size(vmin(35px), vmin(57px));
				}
				@media $display-breakpoints.ms-and-down {
					size(vmin(30px), vmin(49px));
				}
      }
    }
    &__yellow {
      top: 0;
      left: vh(20px);
			opacity: 0;
			transform: translateY(vmin(-10px));
			transition: all .3s ease-in-out .7s;
			@media $display-breakpoints.m-and-down {
				left: vh(15px);
			}
			@media $display-breakpoints.ms-and-down {
				top: vmin(2px);
	      left: vmin(7px);
			}
			.active & {
				transform: translateY(vmin(0px));
				opacity: 1;
			}
      svg {
        size(vh(185px), vh(107px));
				animation: 2s ease-in-out 2s infinite yellow_a;
				@media $display-breakpoints.m-and-down {
					size(vmin(61px), vmin(35px));
				}
				@media $display-breakpoints.ms-and-down {
					size(vmin(53px), vmin(30px));
				}
      }
    }
    &__red {
      bottom: 0;
      right: 0;
			opacity: 0;
			transform: translate(vmin(10px), vmin(10px));
			transition: all .3s ease-in-out .8s;
			@media $display-breakpoints.ms-and-down {
				bottom: vmin(-2px);
			}
			.active & {
				transform: translate(vmin(0px), vmin(0px));
				opacity: 1;
			}
      svg {
        size(vh(114px), vh(172px));
				animation: 2s ease-in-out 2s infinite red_a;
				@media $display-breakpoints.m-and-down {
					size(vmin(38px), vmin(57px));
				}
				@media $display-breakpoints.ms-and-down {
					size(vmin(32px), vmin(49px));
				}
      }
    }
  }
}
.about {
  padding-top: vh(107px);
  overflow: hidden;
	@media $display-breakpoints.ms-and-down {
		padding-top: vmin(38px);
		height: vmin(488px);
		min-height: 0;
	}
  &__title {
    text-align: center;
    max-width: vh(1024px);
    margin: vh(35px) auto; 0;
    z-index: 1;
		transform: translateY(vmin(20px));
		opacity: 0;
		transition: all .3s ease-in-out .45s;
		@media $display-breakpoints.m-and-down {
			opacity: 1;
			transform: none;
		}
		@media $display-breakpoints.ms-and-down {
			margin: vmin(14px) auto; 0;
		}
		.active & {
			transform: translateY(vmin(0px));
			opacity: 1;
		}
  }
  &__subtitle {
    text-align: center;
    z-index: 1;
		opacity: 0;
		transition: all .3s ease-in-out .3s;
		@media $display-breakpoints.m-and-down {
			opacity: 1;
		}
		@media $display-breakpoints.ms-and-down {
      font-size: vmin(18px);
      line-height: vmin(22px);
    }
		.active & {
			opacity: 1;
		}
  }
  &-block {
    &__trigger {
      flexy(center, center);
      absolute();
      margin-left: vh(-30px);
      size(vh(60px));
      border-radius: 50%;
      cursor: pointer;
      z-index: 2;
			transform: scale(0);
      transition: all .3s ease-in-out;
      transform-origin: 50% 50%;
			@media $display-breakpoints.m-and-down {
				transform: none;
			}
			&::after {
				content: '';
				size(100%);
				absolute();
				border-radius: 50%;
				transform-origin: 50% 50%;
				transition: all .3s ease-in-out;
			}
			.active & {
				transform: scale(1);
			}
      .icon {
        fill: none;
        stroke-width: 3;
        stroke: $white;
        margin-right: vh(-3px);
				z-index: 1;
				@media $display-breakpoints.m-and-down {
					size(vmin(10px));
				}
				@media $display-breakpoints.ms-and-down {
					size(vmin(15px));
					margin-right: vmin(-2px);
				}
      }
      &--blue {
        left: vh(409px);
        bottom: vh(276px);
				@media $display-breakpoints.sm-and-down {
					bottom: vh(206px);
					left: vh(369px);
				}
				@media $display-breakpoints.m-and-down {
					bottom: vmin(112px);
					left: vmin(68px);
				}
				@media $display-breakpoints.ms-and-down {
					left: vmin(79px);
					bottom: vmin(130px);
				}
				.active & {
					transition-delay: .6s;
				}
				&::after {
					background: $blue;
				}
        &:hover {
          & ~ .about__bg {
            .about-blue {
              fill: $blue;
            }
          }
          .icon {
            stroke: $blue;
          }
        }
      }
      &--yellow {
        left: 50%;
        bottom: vh(498px);
				@media $display-breakpoints.sm-and-down {
					bottom: vh(515px);
				}
				@media $display-breakpoints.ms-and-down {
					bottom: vmin(274px);
				}
				.active & {
					transition-delay: .75s;
				}
				&:after {
					background: $yellow;
				}
        &:hover {
          & ~ .about__bg {
            .about-yellow {
              fill: $yellow;
            }
          }
          .icon {
            stroke: $yellow;
          }
        }
      }
      &--red {
        right: vh(379px);
        bottom: vh(278px);
				@media $display-breakpoints.sm-and-down {
					bottom: vh(206px);
					right: vh(339px);
				}
				@media $display-breakpoints.m-and-down {
					bottom: vmin(112px);
					right: vmin(68px);
				}
				@media $display-breakpoints.ms-and-down {
					right: vmin(65px);
					bottom: vmin(130px);
				}
				.active & {
					transition-delay: .9s;
				}
				&:after {
					background: $red;
				}
        &:hover {
          & ~ .about__bg {
            .about-red {
              fill: $red;
            }
          }
          .icon {
            stroke: $red;
          }
        }
      }
      &:hover {
				&::after {
					background: $white;
	        transform: scale(1.25);
				}
      }
    }
    &__title {
      absolute();
      text-align: center;
      color: $white;
      pointer-events: none;
      &--blue {
        left: vh(166px);
        bottom: vh(132px);
        max-width: vh(486px);
				@media $display-breakpoints.sm-and-down {
					bottom: vh(70px);
					left: vh(126px);
				}
				@media $display-breakpoints.m-and-down {
					bottom: vmin(10px);
					left: vmin(10px);
					max-width: vmin(120px);
				}
				@media $display-breakpoints.ms-and-down {
					bottom: vmin(30px);
					left: vmin(16px);
					max-width: vmin(126px);
				}
      }
      &--yellow {
        left: 50%;
        transform: translateX(-50%);
        bottom: vh(437px);
				@media $display-breakpoints.sm-and-down {
					bottom: vh(455px);
				}
				@media $display-breakpoints.m-and-down {
					bottom: vmin(165px);
				}
				@media $display-breakpoints.ms-and-down {
					bottom: vmin(220px);
				}
      }
      &--red {
        right: vh(142px);
        bottom: vh(136px);
        max-width: vh(527px);
				@media $display-breakpoints.sm-and-down {
					bottom: vh(74px);
					right: vh(100px);
				}
				@media $display-breakpoints.m-and-down {
					bottom: vmin(10px);
					right: vmin(10px);
					max-width: vmin(140px);
				}
				@media $display-breakpoints.ms-and-down {
					bottom: vmin(10px);
					right: 0;
					max-width: vmin(160px);
				}
      }
    }
  }
  &__bg {
    absolute(50%, 50%);
    transform: translate(-50%, 100%);
    size(100%, auto);
		transition: all .3s ease-in-out .3s;
		@media only screen and (min-width: 1367px) {
	    min-height: 100vh;
			width: auto;
		}
		@media $display-breakpoints.sm-and-down {
			min-height: 100vh;
			width: auto;
		}
		@media $display-breakpoints.m-and-down {
			transform: translate(-50%, -50%);
		}
		@media $display-breakpoints.ms-and-down {
			top: vmin(68px);
			width: vmin(1920px);
		}
		.active & {
			transform: translate(-50%, -50%);
		}
    path {
      pointer-events: none;
    }
    .about-red, .about-yellow, .about-blue {
      pointer-events: auto;
      fill: $white;
      transition: fill .3s ease-in-out;
    }
  }
}
.resources {
  padding-top: vh(107px);
	@media $display-breakpoints.ms-and-down {
		padding-top: vmin(43px);
	}
  &__title {
    text-align: center;
    max-width: vh(1500px);
    margin: vh(35px) auto 0;
		@media $display-breakpoints.ms-and-down {
			max-width: vmin(270px);
	    margin: vmin(16px) auto 0;
		}
		span {
			display: inline-block;
			opacity: 0;
			transform: translateY(vmin(20px));
			transition: all .3s ease-in-out .3s;
			@media $display-breakpoints.m-and-down {
				transform: none;
				opacity: 1;
			}
			&:last-child {
				transition-delay: .45s;
			}
			.active & {
				transform: translateY(vmin(0px));
				opacity: 1;
			}
		}
  }
  &__subtitle {
    text-align: center;
		opacity: 0;
		transition: all .3s ease-in-out .3s;
		@media $display-breakpoints.m-and-down {
			opacity: 1;
		}
		@media $display-breakpoints.ms-and-down {
      font-size: vmin(18px);
      line-height: vmin(22px);
    }
		.active & {
			opacity: 1;
		}
  }
  &__inner {
    width: vh(1289px);
    margin: vh(86px) auto 0;
    flexy(flex-start, flex-start);
    flex-wrap: wrap;
		opacity: 0;
		transform: translateY(vmin(20px));
		transition: all .3s ease-in-out .6s;
		@media $display-breakpoints.m-and-down {
			transform: none;
			opacity: 1;
			width: 100%;
		}
		@media $display-breakpoints.ms-and-down {
			margin-top: vmin(30px);
		}
		.active & {
			transform: translateY(vmin(0px));
			opacity: 1;
		}
  }
  &-block {
    width: 33.33%;
    text-align: center;
    padding: vh(34px) 0 vh(75px);
    max-height: vh(256px);
    border-bottom: 1px solid rgba($black-l, .1);
    border-right: 1px solid rgba($black-l, .1);
		@media $display-breakpoints.m-and-down {
			width: 50%;
		}
		@media $display-breakpoints.ms-and-down {
			max-height: none;
			padding-bottom: vmin(20px);
			padding-top: vmin(18px);
		}
    &:nth-child(1) {
			@media $display-breakpoints.m-and-down {
				border-top: 1px solid rgba($black-l, .1);
			}
			@media $display-breakpoints.ms-and-down {
				min-height: vmin(167px);
			}
		}
    &:nth-child(2) {
			@media $display-breakpoints.m-and-down {
				border-right: none;
				border-top: 1px solid rgba($black-l, .1);
			}
			@media $display-breakpoints.ms-and-down {
				min-height: vmin(167px);
			}
		}
    &:nth-child(3) {
      border-right: none;
			@media $display-breakpoints.m-and-down {
				border-right: 1px solid rgba($black-l, .1);
			}
			@media $display-breakpoints.ms-and-down {
				min-height: vmin(206px);
			}
      .resources-block__title {
        max-width: vh(295px);
        margin-left: auto;
        margin-right: auto;
				@media $display-breakpoints.ms-and-down {
					max-width: vmin(134px);
				}
      }
    }
    &:nth-child(4), &:nth-child(5) {
      border-bottom: none;
    }
		&:nth-child(4) {
			@media $display-breakpoints.m-and-down {
				border-bottom: 1px solid rgba($black-l, .1);
				border-right: none;
			}
			@media $display-breakpoints.ms-and-down {
				min-height: vmin(206px);
			}
		}
    &:nth-child(5) {
			@media $display-breakpoints.ms-and-down {
				min-height: vmin(235px);
			}
		}
    &:nth-child(6) {
      border-right: none;
      border-bottom: none;
			@media $display-breakpoints.ms-and-down {
				min-height: vmin(235px);
			}
      .resources-block__title {
        max-width: vh(362px);
        margin-left: auto;
        margin-right: auto;
      }
			.resources-block__subtitle {
				bottom: vh(2px);
			}
    }
    &.hovered {
      cursor: pointer;
			@media $display-breakpoints.ms-and-down {
				cursor: default;
			}
      &:hover {
        .resources-block__top, .resources-block__title {
          transform: translateY(vh(-20px)) translateZ(0);
					@media $display-breakpoints.ms-and-down {
						transform: none;
					}
        }
        .resources-block__title {
          color: $white;
					@media $display-breakpoints.ms-and-down {
						color: $black-l;
					}
        }
        .resources-block__subtitle {
          opacity: 1;
        }
        &::after {
          opacity: 1;
        }
      }
      &::after {
        content: '';
        width: calc(100% + 40px);
        height: calc(100% + 40px);
        absolute(-20px, -20px);
        background: $red;
        opacity: 0;
        transition: opacity .3s ease-in-out;
				@media $display-breakpoints.ms-and-down {
					display: none;
				}
      }
    }
    &__top {
      flexy(center, center);
      size(vh(95px));
      background: $blue;
      border-radius: 50%;
      margin: 0 auto;
      z-index: 2;
      transition: transform .3s ease-in-out;
			@media $display-breakpoints.ms-and-down {
				size(vmin(50px));
			}
    }
    &__img {
      display: block;
      size(vh(45px), auto);
      transform: translateZ(0);
			@media $display-breakpoints.ms-and-down {
				width: vmin(24px);
			}
    }
    &__title {
      margin-top: vh(17px);
      z-index: 2;
      transition: all .3s ease-in-out;
			@media $display-breakpoints.ms-and-down {
	      max-width: vmin(134px);
				margin-left: auto;
				margin-right: auto;
				margin-top: vmin(11px);
	    }
    }
    &__subtitle {
      width: 100%;
      absolute(auto, 50%, vh(22px), auto);
      transform: translateX(-50%);
      max-width: vh(381px);
      color: $white;
      margin: 0 auto;
      opacity: 0;
      pointer-events: none;
      z-index: 2;
      transition: opacity .3s ease-in-out;
			@media $display-breakpoints.ms-and-down {
				position: static;
				opacity: 1;
				transform: none;
				color: $black-l;
				margin-top: vmin(9px);
				max-width: vmin(135px);
				margin-left: auto;
				margin-right: auto;
			}
    }
  }
}
.services {
	padding-top: vh(152px);
	@media $display-breakpoints.ms-and-down {
		padding-top: vmin(58px);
	}
	&.active {
		&::after {
			opacity: 1;
			transform: translateY(vmin(0px));
		}
	}
	&::after {
		content: '';
		size(vh(910px), vh(549px));
		absolute(auto, auto, 0px, 0px);
		background: url('../img/pattern.png') 0 0 no-repeat;
		background-size: contain;
		pointer-events: none;
		opacity: 0;
		transform: translateY(vmin(20px));
		transition: all .3s ease-in-out .55s;
		@media $display-breakpoints.sm-and-down {
			bottom: vh(-60px);
		}
		@media $display-breakpoints.m-and-down {
			transform: none;
			opacity: 1;
			bottom: vmin(-87px);
		}
		@media $display-breakpoints.ms-and-down {
			bottom: vmin(-154px);
			background-size: 75% auto;
			background-position: top right;
		}
	}
	&__title {
    margin-bottom: vh(71px);
		opacity: 0;
		transform: translateY(vmin(20px));
		transition: all .3s ease-in-out .3s;
		@media $display-breakpoints.m-and-down {
			transform: none;
			opacity: 1;
			margin-bottom: vmin(100px);
		}
		@media $display-breakpoints.ms-and-down {
			margin-bottom: vmin(124px);
		}
		.active & {
			opacity: 1;
			transform: translateY(vmin(0px))
		}
  }
  &__inner {
    padding-left: 50%;
		z-index: 1;
		@media $display-breakpoints.m-and-down {
			padding-left: vmin(23px);
		}
  }
	&__img {
		absolute(0px, 0px);
		size(vh(770px), vh(892px));
		display: block;
		opacity: 0;
		transform: translateY(-100%);
		transition: all .3s ease-in-out .55s;
		@media $display-breakpoints.sm-and-down {
			left: vh(-200px);
		}
		@media $display-breakpoints.m-and-down {
			opacity: 1;
			width: vmin(178px);
			height: auto;
			left: auto;
			right: 0;
			transform: none;
		}
		.active & {
			opacity: 1;
			transform: translateY(0%);
		}
	}
	&-blocks {
		counter-reset: services;
		opacity: 0;
		transform: translateY(vmin(30px));
		transition: all .3s ease-in-out .45s;
		@media $display-breakpoints.m-and-down {
			transform: none;
			opacity: 1;
		}
		.active & {
			opacity: 1;
			transform: translateY(vmin(0px))
		}
	}
	&-block {
		&::before {
			counter-increment: services;
			content: "0" counter(services);
			color: $orange;
			font: 400 vh(20px) $font-base;
	    line-height: vh(37px);
			margin-right: vh(95px);
			@media $display-breakpoints.ms-and-down {
	      font-size: vmin(15px);
	      line-height: vmin(18px);
				margin-right: vmin(32px);
	    }
		}
		& + .services-block {
			margin-top: vh(31px);
		}
	}
}
.prom {
  padding-top: vh(152px);
	@media $display-breakpoints.ms-and-down {
		padding-top: vmin(44px);
	}
  &__title {
    text-align: center;
    max-width: vh(1600px);
    margin: 0 auto vh(39px);
		opacity: 0;
		transform: translateY(vmin(20px));
		transition: all .3s ease-in-out .3s;
		@media $display-breakpoints.sm-and-down {
			max-width: vh(1200px);
		}
		@media $display-breakpoints.m-and-down {
			transform: none;
			opacity: 1;
		}
		@media $display-breakpoints.ms-and-down {
			max-width: vmin(300px);
		}
		.active & {
			opacity: 1;
			transform: translateY(vmin(0px))
		}
  }
  &__subtitle {
    text-align: center;
  }
  &__inner {
    width: vh(1455px);
    margin: 0 auto;
    flexy(flex-start, flex-start);
    flex-wrap: wrap;
		opacity: 0;
		transform: translateY(vmin(20px));
		transition: all .3s ease-in-out .45s;
		@media $display-breakpoints.m-and-down {
			transform: none;
			opacity: 1;
			width: 100%;
		}
		.active & {
			opacity: 1;
			transform: translateY(vmin(0px))
		}
  }
  &-block {
    width: 50%;
    text-align: center;
    padding: vh(80px) 0 vh(60px);
    border-bottom: 1px solid rgba($black-l, .1);
    border-right: 1px solid rgba($black-l, .1);
		@media $display-breakpoints.m-and-down {
			width: 100%;
			border-right: none;
		}
		@media $display-breakpoints.ms-and-down {
			padding-top: vmin(24px);
			padding-bottom: vmin(30px);
		}
    &:nth-child(2) {
      border-right: none;
    }
    &:nth-child(3), &:nth-child(4) {
      border-bottom: none;
			padding-top: vh(56px);
			@media $display-breakpoints.m-and-down {
				border-bottom: 1px solid rgba($black-l, .1);
			}
    }
    &:nth-child(4) {
      border-right: none;
    }
    &.hovered {
      cursor: pointer;
      &:hover {
				@media $display-breakpoints.ms-and-down {
					background-color: $red;
				}
        .prom-block__img, .prom-block__title {
          transform: translateY(vh(-40px)) translateZ(0);
					@media $display-breakpoints.ms-and-down {
						transform: translateY(vmin(-20px)) translateZ(0);
					}
        }
        .prom-block__title {
          color: $white;
        }
        .prom-block__subtitle {
          opacity: 1;
					@media $display-breakpoints.ms-and-down {
						max-height: vmin(100px);
					}
        }
        &::after {
          opacity: 1;
        }
      }
      &::after {
        content: '';
        width: calc(100% + 40px);
        height: calc(100% + 40px);
        absolute(-20px, -20px);
        background: $red;
        opacity: 0;
				z-index: 1;
        transition: opacity .3s ease-in-out;
				@media $display-breakpoints.ms-and-down {
					display: none;
				}
      }
    }
    &__img {
      display: block;
      size(vh(176px), auto);
			margin: 0 auto;
      transform: translateZ(0);
			position: relative;
			z-index: 2;
      transition: transform .3s ease-in-out;
			@media $display-breakpoints.ms-and-down {
				width: vmin(140px);
			}
    }
    &__title {
			color: $blue;
			font-weight: bold;
      margin-top: vh(17px);
      z-index: 2;
      transition: all .3s ease-in-out;
			@media $display-breakpoints.ms-and-down {
				margin-top: vmin(10px);
	      font-size: vmin(18px);
	      line-height: vmin(18px);
	    }
    }
    &__subtitle {
      width: 100%;
      absolute(auto, 50%, vh(3px), auto);
      transform: translateX(-50%);
      max-width: vh(580px);
      color: $white;
      margin: 0 auto;
      opacity: 0;
      pointer-events: none;
      z-index: 2;
      transition: opacity .3s ease-in-out;
			@media $display-breakpoints.ms-and-down {
				position: relative;
				left: auto;
				bottom: auto;
				max-height: 0;
				overflow: hidden;
				transform: none;
				max-width: vmin(300px);
				margin-left: auto;
				margin-right: auto;
			}
    }
  }
}
.facts {
	padding-top: vh(162px);
	@media $display-breakpoints.m-and-down {
		padding-bottom: vmin(110px);
	}
	@media $display-breakpoints.ms-and-down {
		padding-top: vmin(72px);
		padding-bottom: vmin(100px);
	}
	&.active {
		&::after {
			opacity: 1;
			transform: translateY(vmin(0px));
		}
		.swiper-pagination {
			opacity: 1;
		}
	}
	&::after {
		content: '';
		size(vh(898px), vh(477px));
		absolute(auto, 0px, 0px, auto);
		background: url('../img/pattern-2.png') 0 0 no-repeat;
		background-size: contain;
		pointer-events: none;
		opacity: 0;
		transform: translateY(vmin(20px));
		transition: all .3s ease-in-out .5s;
		@media $display-breakpoints.sm-and-down {
			left: vh(-160px);
		}
		@media $display-breakpoints.m-and-down {
			transform: none;
			opacity: 1;
		}
		@media $display-breakpoints.ms-and-down {
			background-size: 70% auto;
			background-position: left bottom;
		}
	}
	&__title {
    padding-left: vh(100px);
    margin: 0 auto vh(184px);
		opacity: 0;
		transform: translateY(vmin(20px));
		transition: all .3s ease-in-out .3s;
		@media $display-breakpoints.m-and-down {
			transform: none;
			opacity: 1;
			padding-left: vmin(23px);
			max-width: vmin(120px);
			margin: 0 0 vmin(85px);
		}
		.active & {
			opacity: 1;
			transform: translateY(vmin(0px));
		}
  }
	&__img {
		absolute(auto, auto, 0px, 0px);
		size(vh(771px), vh(850px));
		display: block;
		opacity: 0;
		transform: translateY(100%);
		transition: all .3s ease-in-out .35s;
		@media $display-breakpoints.sm-and-down {
			right: vh(-200px);
			bottom: vh(-200px);
		}
		@media $display-breakpoints.m-and-down {
			transform: none;
			opacity: 1;
			width: vmin(160px);
			height: auto;
			bottom: auto;
			top: 0;
			right: 0;
		}
		.active & {
			opacity: 1;
			transform: translateY(0%);
		}
	}
	.swiper-pagination {
		opacity: 0;
		transition: all .3s ease-in-out .5s;
		@media $display-breakpoints.m-and-down {
			opacity: 1;
		}
	}
	&-block {
		&__outer {
			@media $display-breakpoints.m-and-down {
				width: 50%;
				margin-bottom: vmin(20px);
			}
		}
	}
	&-carousel {
		width: vh(873px);
		margin-left: vh(150px);
		@media $display-breakpoints.m-and-down {
			width: auto;
			margin-left: 0;
		}
		.swiper-wrapper {
			@media $display-breakpoints.m-and-down {
				display: block;
			}
		}
		.swiper-slide {
			&:nth-child(1) {
				.facts-block__outer {
					opacity: 0;
					transform: translateY(vmin(20px));
					transition: all .3s ease-in-out .5s;
					@media $display-breakpoints.m-and-down {
						transform: none;
						opacity: 1;
					}
					.active & {
						opacity: 1;
						transform: translateY(vmin(0px));
					}
					&:nth-child(2) {
						transition-delay: .6s;
					}
					&:nth-child(3) {
						transition-delay: .7s;
					}
				}
			}
			&:nth-child(2) {
				.facts-block__outer {
					&:nth-child(1) {
						@media $display-breakpoints.m-and-down {
							absolute(vmin(-97px), 50%);
						}
						@media $display-breakpoints.ms-and-down {
							top: vmin(-90px);
						}
					}
					&:nth-child(2) {
						@media $display-breakpoints.m-and-down {
							margin-top: vmin(15px);
						}
					}
				}
			}
		}
		&__inner {
			flexy(space-between, flex-start);
			@media $display-breakpoints.m-and-down {
				justify-content: flex-start;
				flex-wrap: wrap;
			}
		}
		&__title {
			color: $red;
			margin-bottom: vh(32px);
			text-align: center;
			@media $display-breakpoints.ms-and-down  {
				margin-bottom: vmin(4px);
			}
		}
		&__subtitle {
			text-align: center;
			max-width: vh(252px);
			margin: 0 auto;
		}
		.swiper-slide {
			&:nth-child(2) {
				.facts-carousel__inner {
					justify-content: center;
					.facts-block__outer {
						& + .facts-block__outer {
							margin-left: vh(79px);
							@media $display-breakpoints.m-and-down {
								margin-left: 0;
							}
						}
					}
				}
			}
		}
	}
}
.contacts {
	padding-top: vh(123px);
	@media $display-breakpoints.m-and-down {
		padding-bottom: vmin(90px);
	}
	@media $display-breakpoints.ms-and-down {
		padding-top: vmin(71px);
		padding-bottom: 0;
		min-height: vmin(530px);
	}
	&.active {
		&::after {
			opacity: 1;
			transform: translateY(vmin(0px));
		}
		.logo {
			opacity: 1;
			transform: translateY(vmin(0px));
		}
	}
	&::after {
		content: '';
		size(vh(998px), vh(566px));
		absolute(auto, auto, 0px, 0px);
		background: url('../img/pattern-3.png') 0 0 no-repeat;
		background-size: contain;
		pointer-events: none;
		opacity: 0;
		transform: translateY(vmin(20px));
		transition: all .3s ease-in-out .5s;
		@media $display-breakpoints.sm-and-down {
			bottom: vh(-130px);
		}
		@media $display-breakpoints.m-and-down {
			transform: none;
			opacity: 1;
		}
		@media $display-breakpoints.ms-and-down {
			background-size: 55%;
			background-position: bottom right;
			bottom: vmin(-40px);
		}
	}
	&__title {
    margin-bottom: vh(45px);
		opacity: 0;
		transform: translateY(vmin(20px));
		transition: all .3s ease-in-out .3s;
		@media $display-breakpoints.m-and-down {
			transform: none;
			opacity: 1;
			max-width: vmin(120px);
			margin-bottom: vmin(55px);
		}
		@media $display-breakpoints.ms-and-down {
			margin-bottom: vmin(52px);
		}
		.active & {
			opacity: 1;
			transform: translateY(vmin(0px));
		}
  }
	&__img {
		absolute(0px, 0px);
		size(vh(770px), vh(892px));
		display: block;
		opacity: 0;
		transform: translateY(-100%);
		transition: all .3s ease-in-out .5s;
		@media $display-breakpoints.sm-and-down {
			left: vh(-200px);
		}
		@media $display-breakpoints.m-and-down {
			opacity: 1;
			width: vmin(178px);
			height: auto;
			left: auto;
			right: 0;
			transform: none;
		}
		.active & {
			opacity: 1;
			transform: translateY(0%);
		}
	}
	&__inner {
		padding-left: 50%;
		@media $display-breakpoints.m-and-down {
			padding-left: vmin(23px);
			flexy(flex-start, flex-start);
			flex-direction: column;
		}
	}
	.logo {
		padding: 0;
		margin-bottom: vh(51px);
		opacity: 0;
		transform: translateY(vmin(-20px));
		transition: all .3s ease-in-out .4s;
		@media $display-breakpoints.m-and-down {
			transform: none;
			opacity: 1;
			order: 2;
		}
		&__img {
			@media $display-breakpoints.m-and-down {
				margin-left: 0;
			}
		}
		&__subtitle {
			margin-top: vh(23px);
			@media $display-breakpoints.m-and-down {
				text-align: left;
			}
		}
	}
	&__address {
		max-width: vh(441px);
		margin-bottom: vh(80px);
		opacity: 0;
		transform: translateY(vmin(20px));
		transition: all .3s ease-in-out .4s;
		@media $display-breakpoints.m-and-down {
			transform: none;
			opacity: 1;
			margin-bottom: vmin(20px);
		}
		@media $display-breakpoints.ms-and-down {
			margin-bottom: vmin(23px);
		}
		.active & {
			opacity: 1;
			transform: translateY(vmin(0px));
		}
	}
	&__email {
		margin-bottom: vh(94px);
		display: inline-block;
		position: relative;
		opacity: 0;
		transform: translateY(vmin(20px));
		transition: all .3s ease-in-out .4s;
		@media $display-breakpoints.m-and-down {
			transform: none;
			opacity: 1;
			margin-bottom: vmin(20px);
		}
		@media $display-breakpoints.ms-and-down {
			margin-bottom: vmin(30px);
		}
		.active & {
			opacity: 1;
			transform: translateY(vmin(0px));
		}
		&::after {
			content: '';
			width: 100%;
			height: 1px;
			background: $blue;
			absolute(auto, 0px, vh(-10px), auto);
			transition: opacity .2s ease-in-out;
		}
		&:hover {
			&::after {
				opacity: 0;
			}
		}
	}
	&__phone {
		display: block;
		opacity: 0;
		transform: translateY(vmin(20px));
		transition: all .3s ease-in-out .4s;
		@media $display-breakpoints.m-and-down {
			transform: none;
			opacity: 1;
			margin-bottom: vmin(40px);
		}
		@media $display-breakpoints.ms-and-down {
			margin-bottom: vmin(47px);
		}
		.active & {
			opacity: 1;
			transform: translateY(vmin(0px));
		}
	}
}
