.table {
  max-width: 576px;
  @media $display-breakpoints.sm-and-down {
    text-align: left;
    max-width: 400px;
  }
  br {
    @media $display-breakpoints.sm-and-down {
      display: none;
    }
  }
  &__head {
    flexy(flex-start, flex-end);
    color: $sand;
    font: 500 18px/24px $font-base;
    padding: 0;
    margin-bottom: 5px;

    .table__col {
      min-width: 120px;
      padding-right: 10px;
      &--name {
        min-width: 375px;
        @media $display-breakpoints.sm-and-down {
          min-width: 0;
        }
      }
      &--prize {
        min-width: 120px;
        @media $display-breakpoints.sm-and-down {
          min-width: 0;
        }
      }
    }
  }
  &__body {
    font: 400 18px/24px $font-base;
    color: $white;
    height: 336px;
    @media $display-breakpoints.ms-and-down {
      font-size: vmin(16px);
      line-height: vmin(20px);
      height: vmin(490px);
    }
    .table__col {
      &:first-child {
        padding-right: 70px;
      }
      @media $display-breakpoints.ms-and-down {
        margin-bottom: vmin(5px);
        padding-left: 0;
        padding-right: 0;
        min-width: 0 !important;
      }
      &--prize {
        width: 201px;
        @media $display-breakpoints.ms-and-down {
          width: 100%;
        }
      }
      &--name {
        min-width: 375px;
        @media $display-breakpoints.sm-and-down {
          min-width: 0;
        }
      }
      .label {
        display none;
        @media $display-breakpoints.ms-and-down {
          font-size: vmin(18px);
          line-height: vmin(24px);
          display: inline;
        }
      }
    }
  }
  &__row {
    flexy(flex-start, center);
    padding: 12px 0;
    @media $display-breakpoints.sm-and-down {
      display: block;
    }
    @media $display-breakpoints.ms-and-down {
      padding: vmin(10px) 0;
    }
  }
  .value {
    @media $display-breakpoints.sm-and-down {
      display: inline-block;
      font: 400 14px/22px $font-base;
    }
    @media $display-breakpoints.ms-and-down {
      font-size: vmin(14px);
      line-height: vmin(22px);
      display: inline;
    }
    a {
      text-decoration: underline;
    }
    &--none {
      width: 90px;
      @media $display-breakpoints.ms-and-down {
        width: vmin(90px);
      }
      &::before {
        content: '';
        absolute(50%, 0px);
        size(100%, 1px);
        background: $white;
      }
    }
  }
}

.state {
  flexy(flex-start, center);
  @media $display-breakpoints.sm-and-down {
    justify-content: center;
  }

  &__icon {
    margin-right: 9px;
    @media $display-breakpoints.ms-and-down {
      margin-right: vmin(9px);
    }
  }

  &__title {
    text-align: left;
  }

  &--win, &--win-p {
    img {
      size(33px);
      display: block;
      @media $display-breakpoints.ms-and-down {
        size(vmin(33px));
      }
    }
    .marked {
      text-decoration: underline;
      color: #00CE00;
    }
  }
  &--lose, &--lose-p {
    font-weight: 400;
    img {
      size(30px, 28px);
      display: block;
      @media $display-breakpoints.ms-and-down {
        size(vmin(30px), vmin(28px));
      }
    }
  }
  &--waiting {
    img {
      size(16px);
      display: block;
      @media $display-breakpoints.ms-and-down {
        size(vmin(16px));
      }
    }
  }
}
