.footer {
  width: 100%;
  padding: 47px 0 42px;
  overflow: hidden;
  border-top: solid 1px $sand;
  // @media $display-breakpoints.ms-and-down {
  //   padding: vmin(17px) vmin(15px) 0;
  // }
  &__logo {
		size(184px, 36px);
    display: block;
    // @media $display-breakpoints.ms-and-down {
    //   size(vmin(132px), vmin(26px));
    //   margin: 0;
    // }
	}
  &__content {
    flexy(space-between, flex-start);
    max-width: 1440px;
    padding: 0 40px;
    margin: 0 auto;
    // @media $display-breakpoints.ms-and-down {
    //   padding: vmin(9px) 0 vmin(16px);
    //   display: flex;
    //   flex-wrap: wrap;
    //   align-items: flex-start;
    //   justify-content: space-between;
    // }
  }
  &__copyright {

  }
  &__terms {

  }
}
