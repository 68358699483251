fontPathsExtended($font){
  src: url($fonts-path+$font+'.eot');
  src: url($fonts-path+$font+'.eot#iefix') format("embedded-opentype"),
       url($fonts-path+$font+'.woff') format("woff"),
       url($fonts-path+$font+'.ttf') format("truetype");
}

fontPaths($font){
  src: url($fonts-path + $font + '.woff') format("woff");
}


if $use-fonts {
  for key, $font in $fonts-arr {
    @font-face {
      font-family: unquote($font.name);
      if $use-fonts-extended {
        fontPathsExtended($font.file);
      } else {
        fontPaths($font.file);
      }
      font-weight: $font.weight;
      font-style: $font.style;
    }
  }
}
