.header {
	fixed(0px, 0px);
	width: 100%;
	right: 0;
	z-index: 99;
	box-sizing: border-box;
	@media $display-breakpoints.m-and-down {
		border-bottom: 1px solid rgba($black-l, .1);
		background-color: $white;
		transform: translateY(-100%);
		transition: transform .3s ease-in-out;
	}
	.menu-opened & {
		@media $display-breakpoints.m-and-down {
			background-color: transparent;
		}
	}
	&.scrolled {
		@media $display-breakpoints.m-and-down {
			transform: translateY(0%);
		}
	}
	&__content {
		flexy(space-between, center);
		padding: vh(31px) vh(100px);
		@media $display-breakpoints.m-and-down {
			padding: vmin(9px) vmin(20px);
		}
	}
	&__logo {
		size(vh(196px), vh(67px));
		opacity: 0;
		transition: opacity .3s ease-in-out;
		@media $display-breakpoints.m-and-down {
			opacity: 1;
		}
		@media $display-breakpoints.ms-and-down {
      size(vmin(96px), vmin(33px));
    }
		&.active {
			opacity: 1;
		}
		.header--services & {
			display: none;
		}
		&--services {
			display: none;
			.header--services & {
				display: block;
			}
		}
	}
	&__menu {
		size(vh(28px), vh(10px));
		opacity: 0;
		pointer-events: none;
		@media $display-breakpoints.sm-and-down {
			opacity: 1;
			pointer-events: auto;
		}
		@media $display-breakpoints.ms-and-down {
			width: vmin(28px);
		}
	}
}
