.mobile-accordion {
  @media $display-breakpoints.ms-and-down {
    &-content {
      margin: 0 -20px;
      padding: 0 20px;
      //height: 215px;
      //overflow: hidden;
    }
    &-btn {
      margin-top: 1.5rem;
    }
  }
}