.field {
  text-align: left;
  flexy(flex-start, center);
  & + & {
    margin-top: 40px;

    @media $display-breakpoints.ms-and-down {
      margin-top: vmin(34px);
    }
  }
  &--checkbox + .field--checkbox {
    margin-top: 13px;
    @media $display-breakpoints.ms-and-down {
      margin-top: vmin(10px);
    }
  }
  &__input,
  &__placeholder,
  &__file,
  &__file .selected {
    border-radius: 5px;
    font: 18px/18px $font-base;
    padding: 13px 18px;

    @media $display-breakpoints.ms-and-down {
      padding: vmin(13px) vmin(18px);
      font-size: vmin(16px);
      line-height: vmin(18px);
    }
  }
  &__input {
    &[type="file"] {
      size(0px);
      position: absolute;
      padding: 0;
      overflow: hidden;
      opacity: 0;
      border: none;
    }
  }
  &__edit {
    absolute(50%, auto, auto, -5px);
    transform: translate(-50%, -50%);
    border: none;
    background: transparent;
    outline: none;
    transition: opacity .3s ease-in-out;
    &.disabled {
      pointer-events: none;
    }
    &._hide {
      opacity: 0;
      pointer-events: none;
    }
    svg.icon {
      position: static;
      size(13px, 16px);
    }
  }
  .suggestions-wrapper {
    absolute(50px, 0px);
    width: 100%;
    background: rgba($black, .8);
    color: $white;
    z-index: 2;
    @media $display-breakpoints.ms-and-down {
      top: vmin(46px);
    }
    .suggestions-suggestions {
      padding: 12px 20px;
      .suggestions-suggestion {
        padding: 2px 0;
      }
    }
  }

  &__input,
  &__file {
    display: block;
    width: 100%;
    border: 1px solid $black;
    color: $black;
    box-sizing: border-box;
    background: transparent;
    appearance: none;
    {hover-focus-active} {
      outline: none;
    }
    .has-error & {
      border-color: red;
    }
    &[disabled] {
      opacity: 0.5;
    }
    textarea& {
      height: 288px;
      width: 320px;
      padding: 12px 19px 12px;
      border: 1px solid $white;
      background: transparent;
      font: 400 18px/24px $font-base;
      text-transform: none;
      color: $white;
      box-sizing: border-box;
      border-radius: 5px;
      resize: none;
      @media $display-breakpoints.ms-and-down {
        height: vmin(288px);
        width: 100%;
        padding: vmin(12px) vmin(19px) vmin(12px);
        font-size: vmin(16px);
        line-height: vmin(24px);
      }
    }
  }
  &__placeholder,
  &--file .selected{
    absolute(0px, 0px);
    color: $black;
    transition: opacity 0.3s, transform 0.3s;
    cursor: text;
    ^[0]__input:focus ~ &,
    ^[0]__input:valid ~ &,
    ^[0].has-focus &,
    ^[0].has-value & {
      opacity: 0;
      transform: translateX(20%);
    }
  }
  &__error {
    width: 185px;
    absolute(auto, 0px, -15px, auto);
    opacity: 0;
    pointer-events: none;
    text-align: left;
    color: $redMain;
    font: 400 10px/12px $font-base;
    transition: opacity .2s ease-in-out;
    @media $display-breakpoints.ms-and-down {
      font-size: vmin(10px);
      line-height: vmin(12px);
      width: 100%;
      transform: none;
      margin-top: vmin(8px);
    }
  }
  &__label {
    relative();
    display: block;
    min-width: 360px;
    @media $display-breakpoints.sm-and-down {
      min-width: 100%;
    }
    &--msg {
      &::after {
        content: '';
        size(40px, 21px);
        absolute(auto, 70px, -20px, auto);
        background: url("../img/Vector 16.png") 0 0 no-repeat;
        background-size: contain;
       }
       .field__placeholder {
         color: $white;
         font-style: italic;
       }
    }
    &--avatar {
      size(100px);
      border-radius: 50%;
      background: transparent;
      padding: 0 !important;
      min-width: 0 !important;
      @media $display-breakpoints.ms-and-down {
        width: vmin(64px) !important;
        height: vmin(64px);
      }
      &::after {
        display: none;
      }
      .field__edit {
        right: 50%;
        left: 50%;
        right: auto;
      }
    }
    &.has-error ~ .field__error {
      opacity: 1;
    }
    &.has-error .field__error {
      opacity: 1;
      @media $display-breakpoints.ms-and-down {
        display: block;
        left: 0;
        top: vmin(56px);
      }
    }
    &--file {
      padding: 11px 20px 11px 20px;
      min-width: 280px;
      margin-top: 10px;
      box-sizing: border-box;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 9px;
      cursor: pointer;
      @media $display-breakpoints.ms-and-down {
        padding: vmin(14px) vmin(20px) vmin(14px) vmin(26px);
        width: vmin(260px);
        margin-top: 0;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 0;
      }
      &:hover {
        svg {
          color: $white;
        }
      }
      svg {
        size(24px);
        absolute(10px, auto, auto, 43px);
        color: $sand;
        transition: color .2s ease-in-out;
        @media $display-breakpoints.ms-and-down {
          size(vmin(24px));
          absolute(vmin(13px), auto, auto, 40px)
        }
      }
      .field__error {
        width: 100%;
      }
      .field__label-text {
        position: static;
        vertical-align: middle;
        background: transparent !important;
        color: $white;
        font: 18px/24px $font-base;
        @media $display-breakpoints.ms-and-down {
          font-size: vmin(18px);
          line-height: vmin(24px);
        }
      }
    }
  }
  &__label-text {
    absolute(-5px, 15px);
    padding: 0 4px;
    background-color: $white;
    font: 400 12px/1 $font-base;
    color:$black;
    z-index: 1;
    @media $display-breakpoints.ms-and-down {
      font-size: vmin(12px);
    }
    ^[0]--required &::after {
      content: '*';
      margin-left: 0.25em;
      vertical-align: top;
      color: $secondary;
    }
  }
  &--textarea {
    align-items: flex-start;
    & ^[0]__label-text {
      margin-top: vmin(16);
    }
  }
}
.flow-textarea {
  overflow: hidden;
  resize: none;

  &-example {
    font: 300 vw(24)/1 $font-base;
    padding: vw(16) 0;
    absolute(100%, 0px);
    width: 100%;
    z-index: -100;
    opacity: 0;
  }
  &-label {
    overflow: hidden;
  }
}
.field--file {
  .field {
    &__input {
      absolute(0px, 0px);
      opacity: 0;
      padding: vmin(7) vmin(20);
      color: $black;
    }
    &__placeholder,
    &__file .selected {
      top: vmin(-3)
      left: vmin(-3)
      bottom: vmin(-3);
      color: $black;
    }
    &__file {
      height: vmin(48)
      .selected {
        text-transform none
      }
    }
    &__btn {
      absolute(vmin(3));
      right: vmin(3);
      font-size: vmin(18);
      padding: 0.5em 1em;
      border: 0;
      background: $white;
      border-radius: vmin(4)
    }
  }
}

.field--checkbox {
  .field {
    &__label {
      flexy(flex-start, center);
    }
    &__check{
      width: 8px;
      height: 4px;
      position: absolute;
      top: 4px;
      left: 3px;
      border: 1px solid $white;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
      z-index: 1;
      transition: opacity .3s ease-in-out;
    }
    &__input {
      absolute();
      opacity: 0;
      pointer-events: none;
      &:checked ~ .field__check, &:checked ~ .field__bg{
        opacity: 1;
      }
    }
    &__bg {
      size(100%);
      background-color: $sand;
      border-radius: 3px;
      opacity: 0;
      transition: opacity .3s ease-in-out;
    }
    &__box {
      flexy(center, center);
      flex: 0 0 16px;
      size(16px);
      border-radius: 3px;
      border: 1px solid $white;
      color: $redMain;
      background: transparent;
      margin-right: 11px;
      cursor pointer;
      &.has-error {
        border-color: $redMain;
        & ~ .field__text {
          color: $redMain;
        }
      }
    }
    &__text {
      font: 400 12px $font-base;
      color: $white;
      @media $display-breakpoints.ms-and-down {
        font-size: vmin(12px);
      }
      a {
        color: inherit;
        text-decoration underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .icon-check {
    size(100%);
    margin-top: vmin(-6);
    margin-right: vmin(-6);
    opacity: 0;
    transition: opacity 0.3s;
    use {
      stroke-width: vmin(6);
      @media $port {
        stroke-width: vmin(6, 320);
      }
    }
    ^[0].is-checked & {
      opacity: 1;
    }
  }
  &.is-checked .icon-check {
    opacity: 1;
  }
}
.form {
  &__col {
    flexy(flex-start, flex-start);
    @media $display-breakpoints.ms-and-down {
      display: block;
    }
  }
  &__row {
    width: 50%;
    @media $display-breakpoints.ms-and-down {
      width: 100%;
    }
    &:last-child {
      width: calc(50% - 40px);
      margin-left: 40px;
      @media $display-breakpoints.ms-and-down {
        width: 100%;
        margin-left: 0;
      }
    }
  }
  &__field {
    width: 100%;
    &.toggle-input {
      display: none;
      &.active {
        display: block;
        input {
          display: block;
        }
      }
      input {
        display: none;
      }
    }
  }
}

.toggle-control {
  width: 280px;
  flexy();
  margin: 0 auto 40px;
  border: 2px solid $sand;
  border-radius: 25px;
  @media $display-breakpoints.ms-and-down {
    border-radius: vmin(25px);
  }
  &__highlight {
    absolute(0px, 0px);
    size(50%, 100%);
    border-radius: 25px;
    background-color: $sand;
    transition: all .3s ease-in-out;
  }
  &__btn {
    width: 50%;
    position: relative;
    text-align: center;
    padding: 11px 0;
    cursor: pointer;
    z-index: 1;
    @media $display-breakpoints.ms-and-down {
      padding: vmin(11px) 0;
    }
    &.active {
      cursor: default;
      pointer-events: none;
    }
    &:nth-child(2).active {
      & ~ .toggle-control__highlight {
        left: 50%;
      }
    }
  }
}

.register-step {
  opacity: 0;
  pointer-events: none;
  max-height: 0;
  overflow: hidden;
  padding-top: 3px;
  transition: opacity .2s ease-in-out;
  &:last-child {
    .popup__btns {
      margin-top: 24px;
      @media $display-breakpoints.ms-and-down {
        margin-top: vmin(24px);
      }
    }
  }
  &.active {
    opacity: 1;
    pointer-events: auto;
    max-height: none;
  }
  &__text {
    text-align: center;
    margin-top: 14px;
    @media $display-breakpoints.ms-and-down {
      text-align: left;
    }
    &:first-child {
      margin-top: 0;
      margin-bottom: 34px;
      @media $display-breakpoints.ms-and-down {
        margin-bottom: vmin(14px);
      }
    }
    a {
      text-decoration: underline;
    }
  }
}

.timer-wrap {
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s ease-in-out;
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}
