//************************
//    VARIABLES
//************************
$gg-gridName = l-grid;
$gg-colName = l-col;
$gg-attributeName = class;
$gg-gutter = 24px;

$gg-mq-list := {
  lg: "screen and (max-width: 80em)", // max 1280px
  md: "screen and (max-width: 64em)", // max 1024px
  sm: "screen and (max-width: 48em)", // max 768px
  xs: "screen and (max-width: 35.5em)" // up to 568px
};

$gg-grids := {
  'g3x3': {
    cols: 3,
    rows: 3
  }
};

str-repeat(q, str)
  $rpt-str = '';
  for i in 1..q
    $rpt-str += str;
    i += 1;
  return $rpt-str;

makeGrid($key, $grid, $gutter = $gg-gutter)
  &[{$gg-attributeName}*=-{$key}]
    $str = '1fr ';
    if $gutter
      $str = '1fr ' + $gutter + ' ';
      grid-gap: $gutter;
    else
      grid-gap: 0;
    -ms-grid-columns: unquote(str-repeat($grid.cols - 1, $str)) 1fr;
    -ms-grid-rows: unquote(str-repeat($grid.rows - 1, $str)) 1fr;
    grid-template-columns: repeat($grid.cols, 1fr);
    grid-template-rows: repeat($grid.rows, 1fr);

/***************
***** GRID *****
***************/
[{$gg-attributeName}*={$gg-gridName}] {
  display-grid();
}

for $key, $grid in $gg-grids {
  [{$gg-attributeName}*={$gg-gridName}] {
    makeGrid($key, $grid);

    @media $display-breakpoints.ms-and-down {
      display: block;
    }
  }
}

/***************
***** COLS *****
***************/
maxCol($grids) {
  $max-col = '1';
  for $key, $grid in $grids {
    $max-col = max($max-col, $grid.cols)
  }
  return $max-col;
}

maxRow($grids) {
  $max-row = '1';
  for $key, $grid in $grids {
    $max-row = max($max-row, $grid.rows)
  }
  return $max-row;
}

makeCols($q, $hasGutter = true) {
  for $i in 1..$q {
    &[{$gg-attributeName}*=-x-{$i}] {
      grid-cell-position(column, $i, $hasGutter);
    }
    &[{$gg-attributeName}*=-w-{$i}] {
      if $hasGutter {
        grid-cell-size(column, $i, $hasGutter);
      } else {
        grid-cell-size-ms(column, $i, $hasGutter);
      }
    }
  }
}
makeRows($q, $hasGutter = true) {
  for $i in 1..$q {
    &[{$gg-attributeName}*=-y-{$i}] {
      grid-cell-position(row, $i, $hasGutter);
    }
    &[{$gg-attributeName}*=-h-{$i}] {
      if $hasGutter {
        grid-cell-size(row, $i, $hasGutter);
      } else {
        grid-cell-size-ms(row, $i, $hasGutter);
      }
    }
  }
}

[{$gg-attributeName}*={$gg-colName}] {
  [{$gg-attributeName}*={$gg-gridName}]> & {
    grid-template(columns, 1fr);
    grid-template(rows, 1fr);
    width: auto;
    &,
    &:not([{$gg-attributeName}*={$gg-colName}-0]) {
      display-grid();
      padding: 0;
    }
  }
  makeCols(maxCol($gg-grids));
  makeRows(maxRow($gg-grids));
}