.layout {
  .swiper {
    &-pagination-bullet {
      size(vh(10px));
      background: transparent;
      border: 1px solid $black;
      opacity: 1;
      margin: 0;
      box-sizing: border-box;
      &-active {
        background: $blue;
        border: none;
      }
    }
  }
}
.swiper-pagination-bullets {
  bottom: vh(272px);
  left: vh(522px);
  .swiper-pagination-bullet {
    margin: 0;
    & + .swiper-pagination-bullet {
      margin: 0 0 0 vh(23px) !important;
    }
  }
}
