$text-color = $black-l;
$link-color = $text-color;
$link-color--active = $link-color;

html, body {
  font-size: $font-size-base;
  line-height: 1.5;
  font-family: $font-base;
  color: $text-color;
}

a {
  color: $link-color;
  text-decoration: none;

  {hover-focus-active} {
    color: $link-color--active;
    outline: 0;
  }
}

sub, sup {
  font-size: 60%;
  vertical-align: super;
  top: initial;
}

.tp-heading {
  &--large {
    font: 700 vh(90px) $font-base;
    line-height: vh(74px);
    @media $display-breakpoints.ms-and-down {
      font-size: vmin(36px);
      line-height: vmin(30px);
    }
  }
  &--big {
    font: 700 vh(70px) $font-base;
    line-height: vh(74px);
    color: $blue;
    @media $display-breakpoints.m-and-down {
      font-size: vmin(25px);
      line-height: vmin(25px);
    }
    @media $display-breakpoints.ms-and-down {
      font-size: vmin(28px);
      line-height: vmin(28px);
    }
  }
  &--medium {
    font: 400 vh(36px) $font-base;
    line-height: vh(45px);
    @media $display-breakpoints.ms-and-down {
      font-size: vmin(18px);
      line-height: vmin(22px);
    }
  }
  &--small-b {
    font: 700 vh(30px) $font-base;
    line-height: vh(34px);
    @media $display-breakpoints.ms-and-down {
      font-size: vmin(18px);
      line-height: vmin(18px);
    }
  }
  &--small {
    font: 400 vh(30px) $font-base;
    line-height: vh(37px);
    @media $display-breakpoints.m-and-down {
      font-size: vmin(13px);
      line-height: vmin(16px);
    }
    @media $display-breakpoints.ms-and-down {
      font-size: vmin(15px);
      line-height: vmin(18px);
    }
  }
}
.tp-body {
  &--medium {
    font: 400 vh(24px) $font-base;
    line-height: vh(30px);
    @media $display-breakpoints.ms-and-down {
      font-size: vmin(15px);
      line-height: vmin(18px);
    }
  }
  &--base {
    font: 400 vh(22px) $font-base;
    line-height: vh(30px);
    @media $display-breakpoints.ms-and-down {
      font-size: vmin(15px);
      line-height: vmin(18px);
    }
  }
  &--small {
    font: 700 vh(20px) $font-base;
    line-height: vh(25px);
    @media $display-breakpoints.ms-and-down {
      font-size: vmin(14px);
      line-height: vmin(16px);
    }
  }
}
b, strong {
  font: inherit;
  font-weight: bold;
}
.tp-text {
  for $align in (left right center) {
    &--{$align} {
      text-align: $align;
    }
  }
  &--light {
    opacity: 0.8;
  }
  &--lighter {
    opacity: 0.6;
  }
  &--lightest {
    opacity: 0.5;
  }
  &--upper {
    text-transform: uppercase;
  }
  &--lower {
    text-transform: lowercase;
  }
  &--truncate {
    truncate();
  }
  &--nowrp {
    white-space: nowrap;
  }
}

p {
  margin: 1.5em 0;
  no-childs-margin();
}

.primary {
  color: $primary;
}

.ifr-video {
  margin: 2.5rem auto;

  &--small {
    max-width: 450px;
  }

  &__inner {
    padding-bottom: 56.25%;
  }

  iframe {
    absolute(0px, 0px);
    size(100%);
    border: 0;
  }
}

.highlight {
  color: yellow;
}

.underline {
  text-decoration: underline;
}
