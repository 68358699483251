.social {
	flexy(center, center, row, wrap);

	&.header-social {
		absolute(-6px, 2.5rem);
		display: none;
	}

	&--inline {
		flex-wrap: nowrap;
	}

	&__link {
		display: block;
		font-size: 2.5rem;
		line-height: 1;
		color: black;
		transition: color 0.2s;
		margin-left: 2.5rem;

		{hover-focus-active} {
			color: black;
			text-decoration: none;
		}
	}

	&__text {
		display: none;
	}

	&__text,
	&__icon {
		vertical-align: middle;
	}
	&__icon {
		padding: 0.325rem;
	}

	&__title {
		width: 100%;
		flex: 0 0 100%;
		font: 500 13px/1 $font-base;
		text-align: center;
		margin: 0 0 0.5rem;
		text-decoration: underline;
	}
	&--inline &__title {
		width: auto;
		flex: 0 1 auto;
		text-align: left;
		margin: 0 2rem 0 0;
	}

	&__delimiter {
		display: none;
	}

}
.ya-share2 {

	&__list {
		padding: 0;
		margin: 0;
		flexy(flex-start, center, row, nowrap);
	}

	&__item {
		display: block;
		margin: 0;

		& + & {
			margin-left: 4px;
		}
	}
	&__link {
		display: block;
		size(1em);
		flex: 0 0 2.5rem;
		font-size: 1rem;
		line-height: 1;
		color: white;
		transition: color 0.2s;
		padding: 0.75rem;

		{hover-focus-active} {
			color: white;
			text-decoration: none;
		}
	}

	&__title {
		display: none;
	}

	&__title,
	&__badge {
		vertical-align: middle;
	}
	&__badge {
		background: transparent;
	}
	&__badge,
	&__icon,
	.icon {
		display: block;
		size(100%);
		box-sizing: border-box;
	}
	&__icon {
		padding: 0;
	}

}
